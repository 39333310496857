import React from "react";
import "./styles.css";

function Source() {
  return (
    <section className="source-ct container">
      <div className="open-source">
        <pre>
          <code language="Solidity">
            <ol>
              <li>{` CÓDIGO FONTE ZIYON ION 2023`}</li><br/><br/>
              <li>{` Pragma solidity >0.4.0 <= 0.9.0;`}</li><br/>
              <li>{` contract ZIYON is Context, IBEP20, Ownable {`}</li>
              <li>{`   using SafeMath for uint256;`}</li><br/>

              <li>{`   mapping (address => uint256) private _balances;`}</li>
              <li>{`   mapping (address => mapping (address => uint256))`}</li>
              <li>{`   private _allowances;`}</li>
              <li>{`   uint256 private _totalSupply;`}</li>
              <li>{`   uint8 private _decimals;`}</li>
              <li>{`   string private _symbol;`}</li>

              <li>{`   string private _name;`}</li><br/>
              <li>{`   constructor() {`}</li>
              <li>{`     _name = "ZIYON";`}</li>
              <li>{`     _symbol = "ION";`}</li>
              <li>{`     _decimals = 8;`}</li>
              <li>{`     _totalSupply = 100000000 * (10**uint256(_decimals));`}</li>
              <li>{`     _balances[msg.sender] = _totalSupply;`}</li>
              <li>{`     _maxTransactionAmount = 100000 * (10**uint256(_decimals));`}</li>
              <li>{`     emit Transfer(address(0), msg.sender, _totalSupply);`}</li>
              <li>{`   }`}</li><br/>

              <li>{`   function decimals() external view returns (uint8) {`}</li>
              <li>{`     return _decimals;`}</li>
              <li>{`   }`}</li><br/>

              <li>{`   function symbol() external view returns (string memory) {`}</li>
              <li>{`     return _symbol;`}</li>
              <li>{`   }`}</li><br/>

              <li>{`   function name() external view returns (string memory) {`}</li>
              <li>{`     return _name;`}</li>
              <li>{`   }`}</li><br/>

              <li>{`   function totalSupply() external view returns (uint256) {`}</li>
              <li>{`     return _totalSupply;`}</li>
              <li>{`   }`}</li><br/>

              <li>{`   function transfer(address recipient, uint256 amount) external returns (bool) {`}</li>
              <li>{`     _transfer(_msgSender(), recipient, amount);`}</li>
              <li>{`     return true;`}</li>
              <li>{`   }`}</li>
            </ol>
          </code>
        </pre>
      </div>
      <div className="source-content">
        <h1>Transparência</h1>
        <p className="source-info">Para nos tornarmos um dos grupos privados de investimento mais poderosos e independentes do mundo, nossa primeira regra é a transparência total. Só assim podemos conquistar a confiança de nossos clientes. Somos liberais financeiros, e esse é o nosso compromisso primordial, seguido à risca. Nesse aspecto, não abrimos exceções e nem negociamos. Acreditamos que seu investimento ou valor sob custódia pertence exclusivamente a você e a mais ninguém. Estamos aqui para preencher uma lacuna obscura e preocupante na economia atual.</p>
        <p>Nosso código é open-source e o contrato ZIYØN é baseado em padrões como IBEP20 e Ownable, com implementações de SafeMath para garantir operações seguras. O token IØN tem um suprimento total de 100 milhões de unidades, 8 casas decimais, e oferece transparência total na distribuição e nas transações, promovendo uma economia aberta e colaborativa.</p>
        <p className="founders">
          01º - Padrão BEP-20: A ZIYØN é construído de acordo com o padrão BEP-20, o que significa que é compatível com carteiras e exchanges que suportam tokens BSC.<br/><br/>
          02º - Segurança: O contrato inteligente da ZIYØN implementa práticas de segurança robustas, mitigando riscos de ataques e garantindo a integridade dos fundos dos usuários.<br/><br/>
          03º - Escalabilidade: A Binance Smart Chain oferece alta escalabilidade, permitindo que a ZIYØN processe um grande volume de transações com tempos de confirmação rápidos e taxas mínimas.<br/><br/>
          04º - Limite Máximo Por Transação: A ZIYØN implementa um limite máximo de transação de 100 mil moedas para evitar manipulação de mercado e garantir a estabilidade do preço.<br/><br/>
        </p>
        <div onClick={() => window.open('https://github.com/ziyon-fr/ion-crypto', '_blank')} className="source-code">Ver Código Fonte</div>
      </div>
    </section>
  );
}

export default Source;

import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, child } from "firebase/database";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB6PbcLAwtAvia_-h9RMOknSjW7ueKB-kY",
  authDomain: "ziyon-ios-72f4b.firebaseapp.com",
  projectId: "ziyon-ios-72f4b",
  storageBucket: "ziyon-ios-72f4b.appspot.com",
  messagingSenderId: "593461080038",
  appId: "1:593461080038:web:ae3d6403f5c1443d493027",
  measurementId: "G-2H6HXG96D7",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app);

export {
  database,
  ref,
  set,
  get,
  child,
  firestore,
  collection,
  doc,
  getDoc,
  getDocs,
};

import React from "react";
import "./styles.css";

import {
    circle,
    checkFill
  } from "../../../../services/imports/icons";

function Roadmap() {
  return (
    <section className="roadmap">
        <div className="roadmap-title">
            <h1>Nosso Roadmap</h1>
            <p>Entenda nossos objetivos e veja quais metas já atingimos e em quais estamos atuando.</p>
        </div>
        <div className="container roadmap-ct">
            <div>
                <div className="roadmap-info info-done">
                    <img loading="lazy" alt="loteria" src={checkFill} />
                    <h2>01</h2>
                </div>
                <p>Desenvolvimento</p>
                <h1>Construção da moeda e investimento.</h1>
                <p>Foram gastos 365 dias para criar o ecossistema, incluindo: website, aplicativo, construção e testes da moeda na blockchain.</p>
            </div>
            <div>
                <div className="roadmap-info">
                    <img loading="lazy" alt="loteria" src={circle} />
                    <h2>02</h2>
                </div>
                <p>Aquisição de clientes</p>
                <h1>Marketing e alcance de mil clientes.</h1>
                <p>Como em todo projeto, os primeiros passos são os mais difíceis. Trabalhamos dia e noite para destacar a moeda em nível global.</p>
            </div>
            <div>
                <div className="roadmap-info ">
                    <img loading="lazy" alt="loteria" src={circle} />
                    <h2>03</h2>
                </div>
                <p>Corretoras e carteiras</p>
                <h1>Listagem em corretoras e carteiras.</h1>
                <p>Todos os nossos recursos estão sendo empenhados para que possamos listar nossa moeda em grandes carteiras e exchanges, como a Binance.</p>
            </div>
        </div>
    </section>
  );
}

export default Roadmap;


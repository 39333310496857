import React from "react";
import "./styles.css";

import {
  arrowRightShort,
  chevronRight
} from "../../../../services/imports/icons";

import {
  wave
} from "../../../../services/imports/videos";

function Header() {
  return (
    <header className="main">
      <div className="ct-video">
        <video loading="lazy" className="background-video" autoPlay muted loop playsInline>
          <source src={wave} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="content">
        <div className="explore"><p>Explore via DEX PancakeSwap <span className="explore-bar">|</span><span onClick={() => window.open('https://pancakeswap.finance/swap?outputCurrency=0x0c35c6D7c7A21f3299285840710437C50f608B19', '_blank')} className="explore-select">Explorar</span></p> <img loading="lazy" alt="arrow right" src={arrowRightShort} /></div>
        <h2>Cresça em seus próprios termos.</h2>
        <h1>Uma moeda moderna para um mundo moderno.</h1>
        <p className="legend">Alto desempenho, rapidez e segurança, uma moeda criada para ser um investimento.</p>
        <div onClick={() => window.open('https://www.ziyonbank.fr', '_blank')} className="btn-invest"><p>Investir via ziyøn</p> <img loading="lazy" alt="chevron right" src={chevronRight} /></div>
      </div>
    </header>
  );
}

export default Header;


import React from "react";
import "./styles.css";

import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

function Whitepaper() {
  return (
    <>
      <Navigation />
      <main className="container whitepaper">
        <section>
            <h1>ZIYØN (IØN) - Whitepaper Oficial</h1>
            <p>
            Cresça em seus próprios termos. Uma moeda moderna para um mundo
            moderno. Este documento descreve a estrutura, funcionamento e visão
            de longo prazo da ZIYØN (IØN), projetada para oferecer soluções
            financeiras de última geração para investidores institucionais e
            individuais.
            </p>
        </section>
        <section>
            <h2 className="section-title">Introdução</h2>
            <p>
            A ZIYØN (IØN) é uma criptomoeda de nova geração desenvolvida para
            proporcionar alta velocidade, escalabilidade e segurança, utilizando
            a Binance Smart Chain (BSC). Com um enfoque em estabilidade e
            usabilidade no mundo real, a IØN combina características
            descentralizadas e centralizadas para atender tanto investidores que
            buscam autonomia completa quanto aqueles que preferem simplicidade e
            suporte institucional.
            </p>
            <p>
            Este whitepaper fornece uma visão detalhada sobre a arquitetura da
            IØN, suas características principais, seu ecossistema de segurança e
            governança, além de seu papel na evolução do sistema financeiro
            descentralizado e centralizado.
            </p>
        </section>
        <section>
            <h2 className="section-title">Resumo Executivo</h2>
            <p>
            A ZIYØN (IØN) foi criada com o objetivo de se tornar uma reserva de
            valor confiável e uma plataforma robusta para transações digitais
            rápidas e eficientes. Seus 100 milhões de tokens, com 8 casas
            decimais, fornecem um mecanismo deflacionário projetado para aumentar
            o valor ao longo do tempo. A criptomoeda visa fornecer uma alternativa
            segura e escalável para investidores globais, apoiada em uma
            infraestrutura tecnológica de ponta.
            </p>
        </section>
        <section>
            <h2 className="section-title">Estatísticas e Dados Financeiros</h2>
            <div>
            <p><strong>Liquidez:</strong> R$21.864,20</p>
            <p><strong>Investidores:</strong> 24 Clientes</p>
            <p><strong>Transferências:</strong> 78 Transações</p>
            <p><strong>Suprimento Máximo:</strong> 100.000.000 IØN</p>
            <p><strong>Contrato do Token:</strong> 0x0c35c6D7c7A21f3299285840710437C50f608B19 (8 decimais)</p>
            <p><strong>Distribuição Inicial:</strong> 70% para investidores iniciais, 15% para desenvolvimento, 10% para parcerias estratégicas e 5% para reservas de liquidez.</p>
            </div>
        </section>
        <section>
            <h2 className="section-title">Governança e Compliance</h2>
            <p>
            A ZIYØN é comprometida com práticas de governança que garantem a
            integridade do projeto e a proteção dos investidores. Todas as
            decisões estratégicas são tomadas através de um modelo híbrido de
            governança, com votos da comunidade e do conselho de fundadores.
            </p>
            <p>
            Em termos de compliance, a ZIYØN segue as regulamentações globais de
            KYC (Know Your Customer) e AML (Anti-Money Laundering), garantindo que
            todas as transações sejam seguras e que a plataforma esteja em
            conformidade com as leis financeiras internacionais. Investidores
            institucionais podem contar com a conformidade total com normas
            regulatórias estabelecidas.
            </p>
        </section>
        <section>
            <h2 className="section-title">Características Técnicas</h2>
            <h3>Descentralização Total</h3>
            <p>
            Nossa solução descentralizada é voltada para usuários que desejam
            controle completo sobre seus ativos, proporcionando liberdade total em
            transações. Utilizando a PancakeSwap, qualquer investidor pode comprar
            ou vender IØN diretamente em uma corretora descentralizada, sem
            depender de intermediários.
            </p>
            <h3>Centralização Controlada</h3>
            <p>
            Para investidores que preferem maior conveniência e suporte, a ZIYØN
            oferece uma solução centralizada. A custódia dos ativos é gerida de
            forma segura pela própria ZIYØN, permitindo uma experiência mais
            acessível, com suporte ao cliente dedicado e medidas de segurança
            aprimoradas.
            </p>
            <p>
            Essa dualidade proporciona flexibilidade, permitindo que investidores
            escolham o nível de controle e conveniência que mais se adequa às suas
            necessidades.
            </p>
        </section>
        <section>
            <h2 className="section-title">Segurança e Auditoria</h2>
            <p>
            A segurança é um pilar fundamental da ZIYØN. A infraestrutura da IØN é
            protegida por práticas avançadas de criptografia, que incluem o uso de
            bibliotecas de segurança como SafeMath para mitigar erros comuns de
            estouro. Além disso, o contrato inteligente da IØN foi auditado por
            empresas de segurança blockchain de renome, garantindo que o código
            seja invulnerável a exploits comuns.
            </p>
            <p>
            Auditorias regulares garantem que a ZIYØN esteja sempre em conformidade
            com as melhores práticas de segurança. Atualizações contínuas no
            código garantem resiliência contra novos vetores de ataque e a
            integridade de todos os fundos dos usuários.
            </p>
        </section>
        <section>
            <h2 className="section-title">Economia de Token (Tokenomics)</h2>
            <p>
            O modelo econômico da IØN é projetado para criar valor contínuo para
            seus detentores. Com um suprimento total fixo de 100.000.000 IØN, o
            modelo deflacionário garante que o valor dos tokens aumente com o
            tempo, à medida que a demanda por transações na plataforma cresce.
            </p>
            <p>
            As transações realizadas na rede ZIYØN estão sujeitas a uma pequena
            taxa que é usada para recompensar validadores e manter a segurança da
            rede. A queima periódica de tokens garante que o suprimento total
            diminua ao longo do tempo, criando uma pressão positiva no preço.
            </p>
        </section>
        <section>
            <h2 className="section-title">Integração com o Ecossistema Financeiro</h2>
            <p>
            A ZIYØN está preparada para integrar-se com soluções financeiras
            globais, fornecendo APIs robustas e compatíveis para bancos e
            instituições financeiras. Além disso, a IØN oferece uma plataforma
            fácil de usar para integração com carteiras digitais, plataformas de
            pagamento e outros serviços financeiros.
            </p>
            <p>
            Nossas parcerias estratégicas com provedores de pagamento garantem que
            a ZIYØN possa ser utilizada em uma variedade de aplicações do mundo
            real, incluindo pagamentos de e-commerce, transferências
            internacionais e investimentos em ativos digitais.
            </p>
        </section>
        <section>
            <h2 className="section-title">Roadmap e Futuro</h2>
            <h3>Fase 1: Desenvolvimento e Lançamento</h3>
            <p>
            A Fase 1 foi concluída com sucesso, focando no desenvolvimento do
            contrato inteligente, testes rigorosos de segurança e lançamento
            inicial da moeda. Este período incluiu também a criação da
            infraestrutura da Binance Smart Chain para suportar a escalabilidade.
            </p>

            <h3>Fase 2: Expansão de Mercado</h3>
            <p>
            Atualmente, estamos focados na fase de aquisição de investidores e
            parcerias estratégicas, com o objetivo de expandir nossa base de
            usuários para além de 1.000 investidores. Esta fase inclui campanhas
            de marketing digital e presença em conferências financeiras globais.
            </p>

            <h3>Fase 3: Listagem em Corretoras</h3>
            <p>
            O próximo passo é a listagem da IØN em corretoras de grande porte,
            como Binance, FTX e KuCoin, aumentando ainda mais a liquidez e a
            visibilidade da moeda.
            </p>
        </section>
        <section>
            <h2 className="section-title">Conclusão</h2>
            <p>
            A ZIYØN (IØN) é mais do que uma criptomoeda – é uma revolução na forma
            como entendemos o valor e as transações financeiras. Projetada para
            oferecer uma experiência integrada e fluida, a IØN tem o potencial de
            transformar o futuro das finanças digitais e tradicionais. Acreditamos
            que, com a combinação de tecnologia, inovação e governança robusta, a
            ZIYØN irá redefinir os padrões da economia digital global.
            </p>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Whitepaper;

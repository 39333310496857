import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

import {
  brandWhite
} from "../../services/imports/icons";

function Footer() {
  return (
    <footer className="border-top nav-bottom">
      <div className="container">
        <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5">
          <div className="col mb-3">
            <div className="brand mb-4">
              <img loading="lazy" alt="logo" src={brandWhite} />
            </div>
            <p className="copy-footer">&copy; 2024 ZIYØN IØN<br/>SIREN 97830392300012</p><br/>
            <p>Uma ótima solução para gerenciar sua vida. ZIYØN é um banco internacional que fornece soluções de gestão financeira, incluindo investimentos e moedas.</p>
          </div>
          <div className="col mb-3 col-2-footer second"></div>
          <div className="col mb-3 col-2-footer"></div>
          <div className="col mb-3">
            <h5 className="mb-4">Outros assuntos</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="https://pancakeswap.finance/swap?outputCurrency=0x0c35c6D7c7A21f3299285840710437C50f608B19" className="nav-link p-0" target="_blank" rel="noopener noreferrer">
                 PancakeSwap
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://ziyon.fr" className="nav-link p-0 " target="_blank" rel="noopener noreferrer">
                 Investir via ziyøn
                </a>
              </li>
              <li className="nav-item mb-2">
              <Link to="/wallet">Carteira</Link>
              </li>
            </ul>
          </div>
          <div className="col mb-3">
            <h5 className="mb-4">Suporte</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="https://wa.me/5511937077888" className="nav-link p-0" target="_blank" rel="noopener noreferrer">
                 WhatsApp
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="https://www.instagram.com/ziyon.br" className="nav-link p-0" target="_blank" rel="noopener noreferrer">
                 Instagram Ion
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="mailto:contact@ziyon.fr" className="nav-link p-0">
                 contact@ziyon.fr
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import "./styles.css";

function About() {
  return (
    <section className="about-coin">
        <h1>Diversifique sua <br/>carteira com a gente</h1>
        <p>A ZIYØN (IØN) é uma moeda digital descentralizada criada com o objetivo de ser uma reserva de valor. Com uma oferta limitada de 100.000.000 e 8 casas decimais, ela se destaca por sua escassez e valor intrínseco, beneficiando-se da alta velocidade e escalabilidade da Binance Smart Chain. Projetada para ser eficiente e aumentar o seu valor de capital, a IØN é ideal para quem busca um investimento sólido e duradouro, com potencial significativo de valorização ao longo do tempo.</p>
    </section>
  );
}

export default About;


import React, { useEffect, useState } from "react";
import "./styles.css";

import { fetchIonInfo } from "../../../../services/api/call";

function CoinData() {
  const [coinData, setCoinData] = useState(null);

  const getCoinData = async () => {
    const data = await fetchIonInfo();
    setCoinData(data);
  };

  const handleInicialData = () => {
    getCoinData();
  };

  useEffect(() => {
    handleInicialData();
  }, []); // eslint-disable-line

  return (
    <section>
    <div className="ct-coin-info">
      <div className="container info-coin">
        {coinData ? (
          <>
            <div>
              <h1>Liquidez</h1>
              <p>{coinData.about.liquidity}</p>
            </div>
            <div>
              <h1>Investidores</h1>
              <p>{coinData.about.investors}</p>
            </div>
            <div>
              <h1>Transferências</h1>
              <p>{coinData.about.transfers}</p>
            </div>
            <div>
              <h1>Total Max Supply</h1>
              <p>{coinData.about.supply}</p>
            </div>
            <div>
              <h1>Contrato do Token (8 decimais)</h1>
              <p>{coinData.about.token}</p>
            </div>
          </>
        ) : (
          <p>Carregando dados...</p>
        )}
      </div>
    </div>
  </section>
  );
}

export default CoinData;


import React from "react";
import "./styles.css";

import {
    network,
    vignette
  } from "../../../../services/imports/icons";

function Pattern() {
  return (
    <section className="pattern-container">
        <div className="world-bkg"></div>
        <div className="content-pattern">
            <div className="pattern-title">
                <h1>Equilíbrio</h1>
                <p>A IØN Crypto da ZIYØN é uma moeda na rede BNB Binance que equilibra os conceitos de centralização e descentralização. Ela oferece tanto facilidade no investimento quanto liberdade de escolha, proporcionando o melhor dos dois mundos.</p>
            </div>
            <div>
                <div><img loading="lazy" alt="loteria" src={network} /><h2>Descentralização</h2></div>
                <p>Se você valoriza a liberdade e deseja ter 100% da posse de seu investimento em sua própria carteira, nossa área descentralizada é a escolha perfeita. Através da corretora DEX PancakeSwap, você pode garantir total controle e custódia integral da IØN, sem qualquer interferência da ZIYØN ou do estado. Esse caminho oferece exclusividade e autonomia total, permitindo que você seja o único responsável por gerenciar e proteger seu investimento, de maneira descentralizada e independente.</p>
            </div>
            <div>
                <div><img loading="lazy" alt="loteria" src={vignette} /><h2>Centralização</h2></div>
                <p>Se a sua prioridade é facilidade, e você prefere não lidar com a responsabilidade direta sobre o ativo ou não possui muito conhecimento técnico, nossa opção centralizada é ideal para você. A ZIYØN disponibiliza, dentro de seu ecossistema, uma maneira simples e acessível de investir em nossa criptomoeda, sem a necessidade de acessar corretoras descentralizadas ou passar por trâmites complexos. Nesta modalidade, a ZIYØN cuida da custódia do ativo, enquanto você tem a oportunidade de se expor ao mundo cripto de forma rápida e descomplicada, aproveitando a segurança e suporte de nossa plataforma centralizada.</p>
            </div>
        </div>
  </section>
  );
}

export default Pattern;


import React, { useEffect } from "react";
import "./styles.css";

import {
  stock
} from "../../../../services/imports/videos";

function Converter() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widgets.coingecko.com/gecko-coin-converter-widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="converter">
      <div className="container content-conv">
        <section className="conv-side-left">
        <video loading="lazy" className="background-video" autoPlay loop muted playsInline>
          <source src={stock} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </section>
        <section className="conv-side-right">
          <h1>Veja o quanto seu <br/>investimento valorizou!</h1>
          <div className="coin-widget">
            <gecko-coin-converter-widget
              locale="pt"
              outlined="true"
              transparent-background="true"
              dark-mode="false"
              coin-id="ziyon"
              initial-currency="brl"
            ></gecko-coin-converter-widget>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Converter;


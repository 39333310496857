import { firestore, doc, getDoc } from './firebase';

export const fetchIonInfo = async () => {
  try {
    const docRef = doc(firestore, 'crypto', 'ion');
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("Documento não encontrado!");
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar dados:", error);
    return null;
  }
};

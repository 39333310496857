import React from "react";

import {
  og,
  tw
} from "../../services/imports/imagens";

import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

import Header from "./sections/Header";
import Coin from "./sections/CoinData";
import Converter from "./sections/Converter";
import About from "./sections/About";
import Pattern from "./sections/Pattern";
import Roadmap from "./sections/Roadmap";
import Source from "./sections/Source";

function Home() {
  return (
    <>
      <img style={{ display: 'none' }} loading="lazy" alt="chevron right" src={og} />
      <img style={{ display: 'none' }} loading="lazy" alt="chevron right" src={tw} />
      <Navigation />
      <Header />
      <Coin />
      <Converter />
      <About />
      <Pattern />
      <Roadmap />
      <Source />
      <Footer /> 
    </>
  );
}

export default Home;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import WhitePaper from "../pages/Whitepaper";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Whitepaper" element={<WhitePaper />} />
    </Routes>
  );
}

export default AppRoutes;
